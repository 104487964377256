<template>

  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="mt-1">
            {{ accountId || '--' }} ({{
              products.length
            }} rows)
          </h4>

        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <div class="mt-1">
          Ttotal Unprocessed : <b> data : {{ totalUnprocessedIsParent }} , offers : {{ totalUnprocessedOffers  }}</b> on {{ totalItems }}

          </div>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="3"
        >
          <div class="d-flex align-items-center justify-content-end">

            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

          </div>
        </b-col>
      </b-row>

    </div>

    <b-overlay
      :show="loading"
      rounded="sm"
    >

      <b-table
        :items="products"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        striped
        responsive
        class="mb-0"
      >

        <template #cell(id)="data">
          <span v-b-tooltip.hover.top="data.item.id">
            #
          </span>
        </template>

        <template #cell(imageLink)="row">
          
          <img
            :src="row.item.imageLink"
            alt="product"
            class="img-fluid"
            style="max-width: 50px;">
        </template>

        <template #cell(children)="row">

          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          {{ row.item.children.length }}
          <b-form-checkbox
            v-if="row.item.children.length"
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
          >
            <span>{{ row.detailsShowing ? 'Hide' : 'Show' }} </span>
          </b-form-checkbox>
        </template>

        <template #cell(priority)="data">
          <select
            class="form-control"
            @change="onPriorityChange($event, data.item.id)"
          >
            <option
              value="2"
              :selected="data.item.priority == 2"
            >
              High
            </option>
            <option
              value="1"
              :selected="data.item.priority == 1"
            >
              Default
            </option>
            <option
              value="0"
              :selected="data.item.priority == 0"
            >
              Low
            </option>
          </select>
        </template>

        <template #cell(lastRankingAt)="data">
          {{ data.item.lastRankingAt|formatDateTime }}
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card>

            <b-table
              :items="row.item.children"
              :fields="childrenFields"
              :tbody-tr-class="'table-primary'"
              striped
              responsive
              size="sm"
              class="mb-0"
            />

            <b-button
              class="mt-1"
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Children
            </b-button>
          </b-card>
        </template>

      </b-table>

      <div
        v-if="rows > 25"
        class="mt-1 d-flex justify-content-center"
      >
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
          @change="handlePageChange"
        />
      </div>

    </b-overlay>

  </b-card>

</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BFormGroup, BFormCheckbox, BBadge, BButton, BPagination, VBTooltip, BOverlay, BRow, BCol, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BButton,
    BPagination,
    BOverlay,
    BBadge,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    vSelect,
    BFormCheckbox,
    BFormGroup,
    ToastificationContent,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      accountId: null,
      searchQuery: null,
      statusFilter: null,
      totalItems: null,
      totalUnprocessedIsParent: null,

      typeFilter: null,
      filter: null,
      filterOn: ['asin', 'sku', 'itemName'],
      statusOptions: [
        'success',
        'error',
        'warning',
      ],
      typeOptions: [
        'orders',
        'order_items',
        'reports',
        'report_document',
        'fba_inventory',
      ],
      products: [],
      perPage: 25,
      fields: ['id', 'asin', 'imageLink', 'sku', 'children', 'itemName', 'buyBoxLandedPrice', 'lowestLandedPrice', 'variationType','lastCatalogDataAt', 'lastOffersAt', 'lastRankingAt'],
      childrenFields: ['asin', 'sku', 'itemName', 'variationType', 'buyBoxLandedPrice', 'lowestLandedPrice', 'lastCatalogDataAt', 'lastOffersAt', 'lastCheckingAgo'],
      current: 1,
      rows: 0,
    }
  },

  mounted() {
    this.accountId = this.$route.params.accountId
    this.loadParentProducts(this.$route.params.accountId, 1)
  },

  methods: {

    handlePageChange(value) {
      this.page = value
      this.loadParentProducts(this.accountId, this.page)
    },

    loadParentProducts(accountId, page = 1) {
      const filters = {}

      if (this.typeFilter) {
        filters.key = this.typeFilter
      }

      if (this.statusFilter) {
        filters.status = this.statusFilter
      }

      this.loading = true
      this.$store.dispatch('amzSellingPartnerAccount/fetchListingParents', { accountId, page, ...filters })
        .then(result => {
          this.products = result.data.docs
          this.totalItems = result.data.totalItems
          this.totalUnprocessedIsParent = result.data.totalUnprocessedIsParent
          this.totalUnprocessedOffers = result.data.totalUnprocessedOffers
          // this.currentPage = result.data.page
          // this.rows =  result.data.totalDocs
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while loading items', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },

    onPriorityChange($event, id) {
      const data = {
        id,
        priority: event.target.value,
      }

      this.$store.dispatch('amzSellingPartnerAccount/updateListingItem', data)
        .then(() => {
          this.showToast('Success', 'Item was updated successfully', 'success')
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while updating item', 'danger')
        })
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },

  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 120px;
    }
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
